import { Link, navigate } from "@reach/router";
import { range } from "lodash";
import { size } from "polished";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components";
import SaveContext from "../../contexts/SaveContext";
import { RootState } from "../../core/store";
import {
  getActiveChip,
  getActiveOrProposedTeamChipName,
  teamChipsHaveChanged,
} from "../../core/store/chips/reducers";
import { getElementsById } from "../../core/store/elements/reducers";
import { showElementSummary } from "../../core/store/elements/thunks";
import { IElement } from "../../core/store/elements/types";
import { getEntry } from "../../core/store/entries/reducers";
import { fetchEntrySummary } from "../../core/store/entries/thunks";
import { IPickLight } from "../../core/store/entries/types";
import {
  getCurrentEvent,
  getNextEvent,
} from "../../core/store/events/reducers";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import {
  changeCaptain,
  changeViceCaptain,
} from "../../core/store/my-team/actions";
import {
  getMyFormation,
  getMyPicksLastUpdated,
  getMyPicksProposed,
  getMyTeamSavingState,
  hasMyTeamChanged,
  isMyTeamValid,
} from "../../core/store/my-team/reducers";
import {
  actionSubstitution,
  fetchMyTeam,
  saveMyTeam,
} from "../../core/store/my-team/thunks";
import { IPickProposed } from "../../core/store/my-team/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { ILoggedInPlayer } from "../../core/store/player/types";
import { formatRawAsISO, formatRawAsLocal } from "../../core/utils/datetime";
import { getChipName } from "../../utils/chips";
import Alert from "../Alert";
import Button from "../Button";
import ChipList from "../Chips/ChipList";
import DeadlineBar from "../DeadlineBar";
import Dialog, { DialogButtonItem } from "../Dialog";
import { StyledElementFixtureBar } from "../ElementFixtureBar";
import Entry from "../Entry";
import Fixtures from "../Fixtures";
import FixturesForElement from "../FixturesForElement/FixturesForElement";
import { Glass, PatternWrapMain } from "../GraphicPatterns";
import HelmetHead from "../HelmetHead";
import { Divider } from "../Keyline";
import LatestAlert from "../LatestAlert";
import { Main, Secondary, Wrapper } from "../Layout";
import LeaderboardAd from "../LeaderboardAd";
import NewsTicker from "../NewsTicker";
import Pitch, { ElementRow } from "../Pitch";
import Title from "../Title";
import DreamTeam from "../icons/DreamTeam";
import SaveBar from "../squad/SaveBar";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import Bench from "./Bench";
import BenchUnit from "./BenchUnit";
import MyTeamTable from "./MyTeamTable";
import PitchFormation from "./PitchFormation";
import TeamSavedModal from "./TeamSavedModal";

const renderPickValue =
  (initProps: { nextEventId?: number }) => (pick: IPickLight) => {
    return (
      <StyledElementFixtureBar>
        <FixturesForElement
          eventId={initProps.nextEventId}
          elementId={pick.element}
        />
      </StyledElementFixtureBar>
    );
  };

const DreamTeamLink = styled(Link)`
  ${size(9)};
  display: block;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }
`;

const MyTeam: React.FC = () => {
  const player = useSelector(getPlayerData) as ILoggedInPlayer; // enforced by EntryRoute
  const activeChip = useSelector(getActiveChip);
  const chipInPlayName = useSelector(getActiveOrProposedTeamChipName);
  const changed = useSelector(hasMyTeamChanged);
  const chipsChanged = useSelector(teamChipsHaveChanged);
  const currentEvent = useSelector(getCurrentEvent);
  const elementsById = useSelector(getElementsById);
  const entry = useSelector((state: RootState) =>
    getEntry(state, player.entry)
  );
  const formation = useSelector(getMyFormation);
  const nextEvent = useSelector(getNextEvent);
  const picks = useSelector(getMyPicksProposed);
  const picksLastUpdated = useSelector(getMyPicksLastUpdated);
  const savingState = useSelector(getMyTeamSavingState);
  const settings = useSelector(getSettings) as ISettings;
  const valid = useSelector(isMyTeamValid);

  const dispatch = useDispatch();

  const { setSaveState, saveState } = React.useContext(SaveContext);

  const makeCaptain = (elementId: number) => dispatch(changeCaptain(elementId));
  const makeViceCaptain = (elementId: number) =>
    dispatch(changeViceCaptain(elementId));
  const save = () => dispatch(saveMyTeam());
  const showElementDialog = (elementId: number) =>
    dispatch(showElementSummary(elementId));
  const substitute = (elementId: number) =>
    dispatch(actionSubstitution(elementId));

  const [pickForMenu, setPickForMenu] =
    React.useState<IPickProposed | null>(null);

  React.useEffect(() => {
    // No more changes to be made, shouldn't be here ...
    if (!nextEvent) {
      setTimeout(() => navigate("/", { replace: true }), 0);
    }
    dispatch(fetchMyTeam());
    dispatch(fetchEntrySummary(player.entry));
  }, [dispatch, nextEvent, player.entry]);

  const handleShowMenuForPickElement = (element: number) => {
    const matches = picks.filter((p) => p.element === element);
    if (matches.length) {
      setPickForMenu(matches[0]);
    }
  };

  const handleHideMenuForPick = () => {
    setPickForMenu(null);
  };

  const handleHideSaveModal = () => {
    setSaveState(null);
  };

  const renderDreamTeam = (pick: IPickLight) =>
    elementsById[pick.element].in_dreamteam ? (
      <DreamTeamLink to="/team-of-the-week/">
        <DreamTeam title="Team of the Week" />
      </DreamTeamLink>
    ) : null;

  const action = (
    type: "substitute" | "makeCaptain" | "makeViceCaptain",
    element: number
  ) => {
    handleHideMenuForPick();
    switch (type) {
      case "substitute":
        substitute(element);
        break;
      case "makeCaptain":
        makeCaptain(element);
        break;
      case "makeViceCaptain":
        makeViceCaptain(element);
        break;
    }
  };

  const showDialog = (element: IElement) => {
    showElementDialog(element.id);
    handleHideMenuForPick();
  };

  const handleSave = () => {
    save();
    // If previous save was made before the deadline of the previous event we set the saveState to be
    // initial to indicate †he initial save within a GW
    if (currentEvent) {
      if (
        picksLastUpdated &&
        new Date(picksLastUpdated) <= new Date(currentEvent.deadline_time)
      ) {
        setSaveState("initial");
      }
    } else {
      // If the game hasn't started yet
      setSaveState("initial");
    }
  };

  const renderMenu = () => {
    const pick = pickForMenu;
    if (!pick) {
      return null;
    }
    const element = elementsById[pick.element];
    const status = pick.subStatus;
    const startMax = settings.squad_squadplay;
    return (
      <Dialog closeDialog={handleHideMenuForPick}>
        <Dialog.Header closeDialog={handleHideMenuForPick}>
          {`${element.first_name} ${element.second_name}`}
        </Dialog.Header>
        <Dialog.Body>
          <ul>
            {status.match(/^(target|)$/) && (
              <DialogButtonItem>
                <Button
                  onClick={() => action("substitute", pick.element)}
                  width={1}
                  variant="secondary"
                >
                  Switch
                </Button>
              </DialogButtonItem>
            )}
            {status === "instigator" && (
              <DialogButtonItem>
                <Button
                  onClick={() => action("substitute", pick.element)}
                  width={1}
                  variant="secondary"
                >
                  Cancel
                </Button>
              </DialogButtonItem>
            )}
            {!status && pick.position <= startMax && !pick.is_captain && (
              <DialogButtonItem>
                <Button
                  onClick={() => action("makeCaptain", pick.element)}
                  width={1}
                >
                  Make Captain
                </Button>
              </DialogButtonItem>
            )}
            {!status && pick.position <= startMax && !pick.is_vice_captain && (
              <DialogButtonItem>
                <Button
                  onClick={() => action("makeViceCaptain", pick.element)}
                  width={1}
                >
                  Make Vice Captain
                </Button>
              </DialogButtonItem>
            )}
            <DialogButtonItem>
              <Button
                onClick={() => showDialog(element)}
                width={1}
                variant="tertiary"
              >
                View Information
              </Button>
            </DialogButtonItem>
          </ul>
        </Dialog.Body>
      </Dialog>
    );
  };

  if (!entry || !picks.length) {
    return null;
  }

  // Create a new function on each render as fixtures could have changed and
  // need to ensure a render of connected subcomponents
  const _renderPickValue = renderPickValue({
    nextEventId: nextEvent?.id,
  });

  return (
    <>
      <HelmetHead
        title="Pick Your Fantasy Football Team | Fantasy Premier League"
        description="To pick your Fantasy Premier League team and consider using one of your chips before the next Gameweek deadline, visit the official website of the Premier League."
      />
      <LeaderboardAd slot="Landscape_Top" id="ism-pick-ad" />
      <Wrapper>
        <Main>
          <LatestAlert />
          <Box mx={2}>
            <Title>Pick Team - {entry.name}</Title>
          </Box>
          <PatternWrapMain showDeepGradient={true}>
            {nextEvent && (
              <Glass>
                <DeadlineBar
                  deadlineISO={formatRawAsISO(nextEvent.deadline_time)}
                  deadlineLocal={formatRawAsLocal(nextEvent.deadline_time)}
                  heading={nextEvent.name}
                  label={nextEvent.name}
                />
                <ChipList chipType="all" />
              </Glass>
            )}
            <Box m={2}>
              <Alert isInline={true}>
                To change your captain use the menu which appears when clicking
                on a player
              </Alert>
            </Box>
            <Tabs>
              <TabPanel label="Pitch View" link="pitch">
                <Box pt={4}>
                  <Pitch sponsor="default">
                    <PitchFormation
                      chipName={chipInPlayName}
                      eventId={nextEvent?.id}
                      formation={formation}
                      picks={picks}
                      renderDreamTeam={renderDreamTeam}
                      renderElementMenu={handleShowMenuForPickElement}
                      renderPickValue={_renderPickValue}
                    />
                    <Bench variant={chipInPlayName}>
                      <ElementRow>
                        {range(
                          settings.squad_squadplay,
                          settings.squad_squadsize
                        ).map((e, i) => (
                          <BenchUnit
                            key={e}
                            chipName={chipInPlayName}
                            eventId={nextEvent?.id}
                            index={i}
                            pick={picks[e]}
                            renderElementMenu={() =>
                              handleShowMenuForPickElement(picks[e].element)
                            }
                            renderDreamTeam={renderDreamTeam}
                            renderPickValue={_renderPickValue}
                          />
                        ))}
                      </ElementRow>
                    </Bench>
                  </Pitch>
                </Box>
              </TabPanel>
              <TabPanel label="List View" link="list">
                <Box my={4}>
                  <MyTeamTable
                    title="Starters"
                    picks={picks.slice(0, settings.squad_squadplay)}
                    renderElementMenu={handleShowMenuForPickElement}
                  />
                  <MyTeamTable
                    title="Substitutes"
                    picks={picks.slice(settings.squad_squadplay)}
                    renderElementMenu={handleShowMenuForPickElement}
                  />
                </Box>
              </TabPanel>
            </Tabs>
          </PatternWrapMain>
          {activeChip && activeChip.chip_type === "transfer" && (
            <Box my={2}>
              <Alert>
                <p>{getChipName(activeChip.name)} Active</p>
              </Alert>
            </Box>
          )}
          {savingState === "saved" ? (
            <Box my={2} role="status" aria-live="polite">
              <Alert>Your team has been saved.</Alert>
            </Box>
          ) : (
            <SaveBar>
              <Button
                onClick={handleSave}
                disabled={
                  (!changed && !chipsChanged) ||
                  !valid ||
                  savingState === "saving"
                }
                width={1}
                variant="secondary"
              >
                Save Your Team
              </Button>
            </SaveBar>
          )}
          {saveState === "initial" && (
            <TeamSavedModal closeDialog={handleHideSaveModal} />
          )}
          {renderMenu()}
          <Box mx={2}>
            <Divider />
          </Box>
          <Box mt="3rem" mb={4}>
            <NewsTicker />
          </Box>
          {nextEvent && <Fixtures eventId={nextEvent.id} />}
        </Main>
        <Secondary>
          <Entry entryId={entry.id} />
        </Secondary>
      </Wrapper>
    </>
  );
};

export { MyTeam as MyTeamTest };
export default MyTeam;
